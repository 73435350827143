footer {
  background-color: #0425CC;
  padding: 1px 0;
  color: #fff;
  width: 100%;
  height: 13.8vh;
}

footer .container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 6fr 3fr 1fr;
  /* grid-gap: 20px; */
}

footer img {
  width: 180px;
}



footer h3 {
  font-size: 15px;
  color: #DEDBDB;
  margin-bottom: 5px;
  text-align: right;
}

footer ul {
  display: block;
}

footer ul li {
  display: block;
  color: silver;
  font-size: small;
  text-align: right;
}

.legal {
  text-align: center;
  padding: 10px;
  background: rgb(190, 185, 185);
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 800px) {
  footer {
    height: 19vh;
  }

  footer img {
    width: 100px;
  }

  footer .container {
    grid-template-columns: repeat(1fr, 1fr, 1fr);
  }
}