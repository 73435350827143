.collab-container {
    background-color: #f0f0f0;
    height: 100%;
    font-size: 1rem;
}

.collab-header{
    height: 9vh;
}

.collab-new {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: rgb(175, 175, 175);
    border-radius: 10px 10px 0 0 ;
}

.collab-desc {
    min-height: 90px;
    display: flex;
    background-color: #fff;
    justify-content: flex-start;
    align-items: center;;
    border-radius: 10px;
}

.collab textarea {
    border-radius: 10px;
    min-height: 50px;
    margin: 0.5rem 0;
}

.collab-dataItems {
    height: 78vh;
    overflow-y: scroll;
}

.collab-deleteButton {
    position: absolute;
    right: 0px;
    top: 0px;

}