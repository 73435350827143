
.overlay-one {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-one {
    background-color: white;
    padding: 0;
    border-radius: 5%;
    width: 700px; 
    height: 500px; 
    display: flex;
    flex-direction: row;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .left-section {
    background-color: #DFE1E0;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .right-section {
    background-color: white;
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
 
  
  .content {
    margin-top: 60px; 
    padding: 10px;
    overflow-y: auto;
    flex: 1;
  }
  .close-button-footer {
    align-self: flex-end;
    margin: 30px;
  }
  .custom-progress-bar-one {
    height: 200px; 
  }