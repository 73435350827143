.jb_card {
  width: 100%;
  max-width: 278px;
  height: 150px;
  font-family: arial;
  border-bottom: 7px solid #0425cc;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  z-index: 9;
}

.cardT {
  width: 340px;
  height: 158px;
  font-family: sans-serif;
  border: 1px solid #cecece;
  border-bottom: 7px solid #0425cc;
  border-radius: 8px;
  cursor: pointer;
}

.detailCards {
  margin-bottom: 10px;
  font-size: 13px;
}
.svgColor {
  color: gray;
}

.box {
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.price {
  color: grey;
  font-size: 15px;
  margin-bottom: 2rem;
}

.pricee {
  color: grey;
  font-size: 16px;
  margin-bottom: 9px;
}

.form-img {
  position: absolute;
  width: 20%;
  left: 15%;
  top: -25px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 22px;
  color: lightslategray;
  /* Add any other custom styles you want */
}

.ej-carouselContainer {
  padding: 40px 0;
  background: #eff0f9;
  position: relative;
}

.slick-slide div {
  margin: 0 0.5rem;
}

.carousalBox_PJ {
  margin-right: 14px;
  margin-bottom: 15px;
  border: 1px solid #cecece;
  position: relative;
  border-radius: 7px;
  padding: 15px;
  border-bottom: 7px solid #0425cc;
  cursor: pointer;
 height: 160px;

}