.notification-dropdown {
    position: relative;
    display: inline-block;
}

.notification-dropdown-buttonn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #333;
    padding: 7px;
    position: relative;
    margin-right: 1.2rem;
    margin-top: 0.7rem;
}

.additional {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0.5rem;
    color: #333;
    padding: 5px;
    position: relative;
    margin-right: 5px;
}

.notification-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #f44336;
    color: #fff;
    font-size: 0.7rem;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-dropdown-content {
    display: block;
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 11;
    width: 400px;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.notification-item {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid gray;
    min-height: 100px;

}

.mdl_img {
    width: 30px;
    height: 20px;
}


/* Add these styles to your existing CSS file or create a new CSS file */

.notification-item.read {
    background-color: #EFF0F9;
}

.notification-item.unread {
    background-color: white;
}



/* .notification-item:hover {
    background-color: #f1f1f1;
} */

.notification-item-icon {
    font-size: 1rem;
    margin-right: 10px;
}

.notification-item-title {
    /* color: grey; */
    /* font-weight: bold; */
    font-size: small;
    margin-bottom: 2px;
}

.notification-item-body {
    font-size: small;
}

.notification-empty {
    padding: 10px;
    font-style: italic;
    color: #999;
    text-align: center;
}

.drp_dwn {
    border: 1px solid gray;
    border-radius: 5px;
    /* background-color: transparent;
    appearance: none; */
    padding: 0;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
}

.my_dp {
    margin-right: 1rem;
}

/* .App {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.deActive {
    margin: 0 15px;
    padding: 5px;
    background-color: #ddd;
    border: none;
    border-radius: 5px;
    cursor: pointer;
} */

/* .ActiveButton {
    background-color: #1a7fe4;
    color: white;
    margin: 0 15px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
} */

/* .NotificationContainer {
    border: 2px solid grey;
    border-radius: 5px;
    margin-top: 10px;
    max-height: 25%;
} */

.msgs {
    margin: 3px;
    padding: 5px;
    background-color: #eee;
    border-radius: 5px;
    font-size: 13px;
}