.leadName {
    font-size: 12px;
    color: rgb(41, 41, 41);
}

.miniButton {
    font-size: 10px;
    cursor: pointer;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 3px;
    margin: 2px;
    color: rgb(41, 41, 41);
}

.miniButton:hover {
    color: #ffffff;
}

.isCursor {
    cursor: pointer;
}

.department_item:hover {
    background-color: #dedede;
    transition: all ease-in-out 0.2s;
    
}

.activeBlock {
    background-color: #dedede;
    transition: all ease-in-out 0.2s;
}

.listContainer{
    max-height: 70vh;
    overflow: auto;
}