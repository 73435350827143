* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

.ej_container {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
}

.contain {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  max-width: 52rem;
  margin: auto;
  position: relative;
  z-index: 9;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_jb {
  width: 38%;
}

.search_lctn {
  width: 38%;
}

ul {
  list-style-type: none;
}

.heading {
  text-align: center;
  width: 40%;
  margin-right: auto;
}

.heading h1 {
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}

.heading p {
  color: rgba(255, 255, 255, 0.969);
}

.headings {
  text-align: left;
  width: 100%;
}

.headingsF {
  text-align: center;
  width: 100%;
}

.headings h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}

.headings p {
  color: #72809d;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.jobSearch-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (max-width: 800px) {

  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading {
    width: 100%;
  }

  .container {
    max-width: 90%;
  }


}