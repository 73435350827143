.custom-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
}

/* Style for input fields */
/* input[type="text"] {
    width: 70%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
} */

/* input[type="text"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
} */

.custom-file-upload {
    /* border: 2px dashed #007bff; */
    /* margin-top: 10px;
    padding: 5px; */
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.intro-video {
    text-align: left;
    /* Align the text to the left */

    /* Add margin for spacing */
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Style for the <b> elements within the div */
.intro-video b {
    font-weight: bold;
    display: block;
    /* Make each <b> element appear on a new line */
    margin-bottom: 10px;
    /* Add space between <b> elements */
}

.remove-button {
    color: red;
    /* Set text color to red */
    text-decoration: underline;
    /* Add underline */
    background-color: transparent;
    /* Remove button background color */
    border: none;
    /* Remove button border */
    cursor: pointer;
    /* Add a pointer cursor on hover for better UX */
}