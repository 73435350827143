.hero {
  /* background-image: url("../../../../assets/images/front.webp"); */
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  min-height: 300px; /* Set a minimum height */
  height: 50vh; /* Set initial height to 100% of the viewport height */
  background-color:#0425CC;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .hero {
    height: auto; /* Set height to auto for smaller screens */
  }
}


.hero .ej_container {
  padding-top: 50px;
}

.hero h1 {
  color: #fff;
  font-size: 45px;
  text-align: left;
  line-height: 60px;
  width: 650px;


}

.hero p {
  color: #fff;
  font-size: 20px;
  opacity: 0.8;
  text-align: left;
  margin-top: 15px;
}

.background-form {
  padding: 40px 0;
  position: relative;
  height: 220px;
  margin-top: 100px;
}

.form-img {
  position: absolute;
  width: 20%;
  left: 15%;
  top: -25px;
  z-index: 8;
}

.my-form {
  border: 3px solid #EFF0F9;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form-imag {
  position: absolute;
  width: 20%;
  right: 14.5%;
  bottom: -120px;
}



.my-form .box {
  padding: 5px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

.my-form .box:nth-child(1) {
  border-left: none;
}

.my-form h4 {
  font-weight: 500;
}

.filter-btn1 {
  background-color: gray;
}

@media (max-width: 768px) {
  .hero {
    background-image: none;
    background-color: #0425CC;
    padding: 5px;
    height: auto;
  }

  .hero .ej_container {
    max-width: 90%;
    text-align: center;
  }

  .hero h1 {
    font-size: 30px !important;
    line-height: 40px;
    width: 100%;
  }

  .hero p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .slider img{
    display: none;
  }
}
/* 
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }

  .my-form .box {
    border-left: none;
  }

  .my-form input {
    width: 100%;
  }
} */

/* .slider {
 position: absolute;
 margin-left: 65%;
top: 185px;
 
  width: 40%;
  height: 70%;
  overflow: hidden;
}

.slider img {
  width: 60%;
  height: 70%;
 /* clip-path: circle(50% at center); 
} */
.slider {
  position: relative;
  width: 100%;
  height: 100%; /* Adjust as needed */
  overflow: hidden;
}

.slider img {
  position: absolute;
  top: 0;
  left: 3%;
  width: auto;
  height: 77%;

  opacity: 0;
  transition: opacity 2.5s ease-in-out; /* Adjust duration and timing function as needed */
}

.slider img.active {
  opacity: 1;
}



.blueCircleBackground {
  position: absolute;
  top: 23%; /* Adjust positioning according to your layout */
  left: 44%;
  transform: translateX(+50%);
  width: 100%; /* Adjust width as needed */
  height: 73%; /* Adjust height as needed */
  max-height: 510px;
  max-width: 550px;
 /* Limit maximum width for larger screens */
  /* background-image: url('../../../../assets/images/bluefront.webp'); Adjust the path to your blue circle image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* Other styles for the blue circle background */
}
