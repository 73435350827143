.location {
  width: 20rem;
}

@media (max-width: 768px) {
  .background-form {
    height: auto;
    padding: 20px 0;
    width: 90%;
    margin-left: 10px;
  }

  .input-group-prepend {
    display: none;
  }

  .input-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-group input {
    width: 100%;
    margin-bottom: 10px;
  }

  .location {
    width: 50%;
  }

  .input-group-text {
    height: 128%;
    background-color: "#0425CC";
    color: white;
  }

  .form-img {
    display: none;
    /* Hide the image on mobile devices */
  }

  .contain {
    width: 100%;
  }

  .my-form {
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
  }
}

.jobSearchForm-wrapperWithRadius {
  display: grid;
  grid-template-columns: 24% 24% 10% 10% 10% 5%;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 10px 0;
  flex-flow: wrap;
}

.jobSearchForm-wrapperWithoutRadius {
  display: grid;
  grid-template-columns: 34% 34% 10% 5%;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 10px 0;
  flex-flow: wrap;
}

.jobSearchForm-searchIcon {
  display: flex;
  padding: 0 5px;
  align-items: center;
  background-color: white;
  border: 1px solid rgba(224, 224, 224, 1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.jobSearchForm-searchInput {
  display: flex;
}

.jobSearchForm-SearchButton {
  height: calc(1.5em + 0.75rem + 2px);
  background-color: #e9ecef;
  color: #495057;
  min-width: 6rem;
  border: unset;
  border: 1px solid #ced4da;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.jobSearchForm-FilterButton {
  min-width: 3rem;
  margin-left: 0.7rem;
  background-color: #e9ecef;
  color: #495057;
  height: calc(1.5em + 0.75rem + 2px);
  border: unset;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.jobSearchForm-FilterButton:hover {
  color: white;
  background: #ced4da;
}

.jobSearchForm-locationInput-input {
  width: 100%;
  border: none;
  color: #495057;
}

.jobSearchForm-locationInput-input:focus {
  outline: none;
}

.jobSearchForm-locationInput button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  fill: #0071c1;
}

.jobSearchForm-locationInput button:before {
  /* content: "X"; */
  fill: blue;
  font-weight: 500;
}

@media (max-width: 992px) {
  .jobSearchForm-wrapperWithRadius,
  .jobSearchForm-wrapperWithoutRadius {
    display: flex;
    gap: 2px;
  }

  .jobSearchForm-searchInput,
  .jobSearchForm-locationInput {
    width: 49%;
  }

  .jobSearchForm-select {
    width: 25%;
  }
}
